.noty_theme__semanticui.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  font-size: 1em;
  border-radius: .28571429rem;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.22) inset, 0 0 0 0 transparent; }
  .noty_theme__semanticui.noty_bar .noty_body {
    padding: 1em 1.5em;
    line-height: 1.4285em; }
  .noty_theme__semanticui.noty_bar .noty_buttons {
    padding: 10px; }

.noty_theme__semanticui.noty_type__alert,
.noty_theme__semanticui.noty_type__notification {
  background-color: #f8f8f9;
  color: rgba(0, 0, 0, 0.87); }

.noty_theme__semanticui.noty_type__warning {
  background-color: #fffaf3;
  color: #573a08;
  box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent; }

.noty_theme__semanticui.noty_type__error {
  background-color: #fff6f6;
  color: #9f3a38;
  box-shadow: 0 0 0 1px #e0b4b4 inset, 0 0 0 0 transparent; }

.noty_theme__semanticui.noty_type__info,
.noty_theme__semanticui.noty_type__information {
  background-color: #f8ffff;
  color: #276f86;
  box-shadow: 0 0 0 1px #a9d5de inset, 0 0 0 0 transparent; }

.noty_theme__semanticui.noty_type__success {
  background-color: #fcfff5;
  color: #2c662d;
  box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent; }
