.bg-primary {
  p {
    color: #000;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 300;
}
#instafeed {
  text-align: center;
  img {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.bg-tr-gray {
  background-color: rgba(100, 100, 100, 0.5);
  padding: 30px;
  color: white;
  text-align: center;
  h2 {
    color: white;
  }
}

#payment-form {
  .form-control {
    margin-bottom: 10px;
  }
}

$apmred: #f53240;

.bg-apmred {
  background-color: $apmred;
  p {
    color: #fff;
  }
  a {
    color: #fff;
  }
}
.bg-apmred-dark {
  background-color: darken($apmred, 10%);
}

#sideNav {
  z-index: 100;
}
@media only screen and (min-width: 767px) {
  #sideNav {
    top: 180px;
    .u-sidebar-navigation__toggler {
      top: 180px;
    }
  }
}
