// Font Families
$g-font-primary: "Roboto", sans-serif;

// Font Sizes
// $g-font-primary-size: 16px;



//
// Colors
//

// Primary Color
$g-color-primary: #1e73be;

// Main Colors
$g-color-main: #000;
$g-color-main-light-v3: #142538;
$g-color-text: #777;
$g-color-secondary-light-v1: #343638;

// Main Secondary
$g-bg-color-secondary: #eef1f8;
$g-bg-color-main-light-v1: #262729;
$g-bg-color-main-light-v2: #2b2d30;
$g-bg-color-main-light-v3: #142538;
